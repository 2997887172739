<template>
  <dashboard-layout
    class="dashboard-page"
    :locations-list="userLocations"
    :current-location="currentUserLocation"
    @location-change="locationChangeHandler"
  >
    <template #outer-content>
      <ui-tabs
        :list="userLocations"
        :preselected-tab-id="currentUserLocation ? currentUserLocation.id : null"
        @tab-change="locationChangeHandler"
      />
    </template>

    <template #side-content>
      <main-side-menu
        :item-list="menuItems"
        active-item-id="main"
      />
      <user-actives
        :units-list="userUnits"
      />
      <user-manager
        :manager-data="userManagerData"
      />
    </template>
    <template #main-content>
      <div class="dashboard-page__summary-table">
        <div
          v-if="!isInvestor && requestStatus"
          class="dashboard-page__request-status"
          :style="divStyle"
        >
          <div class="dashboard-page__request-status-row">
            <img src="../assets/gear_ok.svg" alt="ok">
            <h4>{{ t('dashboardPage.requestSend') }}</h4>
          </div>
        </div>
        <stub-convertation
          class="dashboard-page__stub"
          v-if="!isInvestor && !requestStatus"
          @send-request="sendConversionRequest"
          ref="stubDiv"
        />
        <stub-preamble
          v-if="!isInvestor"
          link="../../preambula_dewis.pdf"
          class="dashboard-page__stub"
        />
        <stub-download-report
          v-if="!isInvestor"
          class="dashboard-page__stub"
        />
        <!--        <div-->
        <!--          v-if="!isInvestor && requestStatus"-->
        <!--          class="dashboard-page__request-status"-->
        <!--          :style="divStyle"-->
        <!--        >-->
        <!--          <div class="dashboard-page__request-status-row">-->
        <!--            <img src="../assets/gear_ok.svg" alt="ok">-->
        <!--            <h4>{{ t('dashboardPage.requestSend') }}</h4>-->
        <!--          </div>-->
        <!--        </div>-->
        <stub-promo-code
          v-if="isInvestor"
          promoCode="viewgainvest"
          link="https://view-ga.ru/booking/"
        />
        <stub-video
          v-if="!isInvestor"
          :title="t('polinaPrikhodko')"
          :text="t('theVideoProvided')"
          videoSource="https://www.youtube.com/embed/0cx0MIELdyM?si=ntDOg5iSk8Z8Iy61"
        />
        <stub-video
          v-if="!isInvestor"
          :title="t('inwisFounder')"
          :text="t('detailedManual')"
          videoSource="https://www.youtube.com/embed/Qp5Xptk0DBk?si=JHEOqtfZqZRNXvcs"
        />
        <div
          v-if="isInvestor"
          class="dashboard-page__about"
        >
          <location-info
            v-if="currentUserLocation && locationInfoData[`${currentUserLocation.id}`]"
            :locationData="locationInfoData[`${currentUserLocation.id}`]"
          />
        </div>
        <div
          v-if="!isInvestor"
          class="dashboard-page__about"
          :class="{
            'dashboard-page__about--not-first':!isInvestor
          }"
        >
          <location-info
            v-for="item in locationInfoDataForZaim"
            :key="item.locationName"
            :locationData="item"
          />
        </div>
        <div
          v-if="isInvestor"
          class="dashboard-page__header"
        >
          <title-main
            :text="t('dashboardPage.tableTitle')"
            icon
          >
            <template #icon>
              <img src="../assets/list-icon.svg" alt="dashboard-icon">
            </template>
          </title-main>
        </div>
        <summary-table
          v-if="isInvestor"
          :class="{
              'loading-stub': isLoadingStatistics,
            }"
        >
          <div class="summary-table__row">
            <div class="summary-table__block">
              <summary-info
                :header="t('dashboardPage.invested')"
                :firstInfo="invested"
              />
            </div>
            <div class="summary-table__block">
              <summary-info
                :header="t('dashboardPage.earned')"
                :firstInfo="totalIncome"
              />
            </div>
            <div class="summary-table__block">
              <summary-info
                :header="t('dashboardPage.roi')"
                :firstInfo="averagePercentForYear"
                :popupData="popupData"
              />
            </div>
            <div class="summary-table__block">
              <summary-info
                :header="t('dashboardPage.monthIncome')"
                :firstInfo="averageMonthlyIncome"
              />
            </div>
          </div>
        </summary-table>
      </div>
      <div
        v-if="isInvestor"
        class="dashboard-page__actual-data"
      >
        <div class="dashboard-page__header">
          <title-main
            :text="t('dashboardPage.dataTableTitle')"
            icon
          >
            <template #icon>
              <img src="../assets/circle-graph-icon.svg" alt="dashboard-booking-icon">
            </template>
          </title-main>

          <popup-dropdown
            v-if="actualDataDates && actualDataDates.length"
            class="date-drop-down"
            :data="actualDataDates"
            :active-item-id="actualDataSelectedDate.id"
            @select-dropdown-item="selectActualDataDateHandler"
            ref="dropdown"
          >
            <secondary-button
              :label="actualDataSelectedDate.label"
            >
              <template #iconAfter>
                <svg width="11" height="6" viewBox="0 0 11 6" xmlns="http://www.w3.org/2000/svg">
                  <path d="M5.49998 6C5.30284 6 5.10572 5.92797 4.95542 5.78422L0.225656
                  1.25832C-0.0752187 0.970413 -0.0752187 0.503627 0.225656 0.21584C0.526409
                  -0.0719468 1.01413 -0.0719468 1.31503 0.21584L5.49998 4.22061L9.68496
                  0.21598C9.98584 -0.0718069 10.4735 -0.0718069 10.7742 0.21598C11.0753
                  0.503767 11.0753 0.970553 10.7742 1.25846L6.04455 5.78436C5.89417 5.92814
                  5.69705 6 5.49998 6Z"/>
                </svg>
              </template>
            </secondary-button>
          </popup-dropdown>
        </div>
        <summary-table
          :class="{
          'loading-stub': isLoadingActualData && (!incomeAmount && !bookingAmount),
        }"
        >
          <div class="summary-table__row">
            <div
              class="summary-table__block"
            >
              <unit-income
                :header-text="t('dashboardPage.income')"
                :price-text="isLoadingActualData ? '' : (incomeAmount ? incomeAmount + ' ₽' : '0₽')"
                :metric-number="incomeAmountPercent"
              >
                <template #link>
                  <more-link
                    label="Подробнее"
                    path="/dashboard/income"
                  />
                </template>
              </unit-income>
            </div>
<!--            <div-->
<!--              class="summary-table__block"-->
<!--            >-->
<!--              <unit-income-->
<!--                :header-text="t('dashboardPage.booking')"-->
<!--                :price-text="isLoadingActualData ? '' : bookingAmount || '0'"-->
<!--                :metric-number="bookingAmountPercent"-->
<!--              >-->
<!--                <template #link>-->
<!--                  <more-link-->
<!--                    label="Подробнее"-->
<!--                    path="/dashboard/booking/"-->
<!--                  />-->
<!--                </template>-->
<!--              </unit-income>-->
<!--            </div>-->
          </div>
        </summary-table>
      </div>
      <div v-if="false" class="dashboard-page__graph">
        <div class="dashboard-page__title">
          <title-secondary
            text="Бронирования юнитов"
          />
          <filter-date
            is-range
            :preselected-range="graphDatesRange"
            :past-dates-options="pastDatesOptionsList"
            :past-date-option-preselected="1"
            @filter-date-select="graphDatesChangeHandler"
          />
        </div>
        <div class="dashboard-page__graph-inner">
          <info-chart
            v-if="bookingGraphData"
            type="area"
            :data="bookingGraphData"
          />
          <stub-block-no-data
            v-else
            style="margin-top: 45px;"
            title="Пока нет данных о бронированиях"
            sub-title="Как только они появятся, вы увидите график на этой странице."
          >
            <template #icon>
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path d="M2 20V3H0V21C0 21.2652 0.105357 21.5196 0.292893 21.7071C0.48043 21.8946
                0.734784 22 1 22H24V20H2Z" fill="#89909E"/>
                <path d="M9 12C9 11.7348 8.89464 11.4804 8.70711 11.2929C8.51957 11.1054 8.26522 11
                8 11H5C4.73478 11 4.48043 11.1054 4.29289 11.2929C4.10536 11.4804 4 11.7348 4
                12V19H9V12Z" fill="#89909E"/>
                <path d="M16 8C16 7.73478 15.8946 7.48043 15.7071 7.29289C15.5196 7.10536 15.2652
                7 15 7H12C11.7348 7 11.4804 7.10536 11.2929 7.29289C11.1054 7.48043 11 7.73478 11
                8V19H16V8Z" fill="#89909E"/>
                <path d="M23 4C23 3.73478 22.8946 3.48043 22.7071 3.29289C22.5196 3.10536 22.2652
                3 22 3H19C18.7348 3 18.4804 3.10536 18.2929 3.29289C18.1054 3.48043 18 3.73478 18
                4V19H23V4Z" fill="#89909E"/>
              </svg>
            </template>
          </stub-block-no-data>
        </div>
      </div>
    </template>
  </dashboard-layout>
</template>

<script>
import { computed, onMounted, ref } from 'vue';
import { useElementSize } from '@vueuse/core';
import { useStore } from 'vuex';
import { updateMoneySum } from '@/helpers/common';
import TitleMain from '@/ui-kit/TitleMain/TitleMain.vue';
import UiTabs from '@/ui-kit/UiTabs/UiTabs.vue';
import DashboardLayout from '@/layouts/DashboardLayout.vue';
import MainSideMenu from '@/components/MainSideMenu/MainSideMenu.vue';
import MoreLink from '@/ui-kit/MoreLink/MoreLink.vue';
import SecondaryButton from '@/ui-kit/SecondaryButton/SecondaryButton.vue';
import PopupDropdown from '@/components/PopupDropdown/PopupDropdown.vue';
import UserActives from '@/components/UserActives/UserActives.vue';
import UnitIncome from '@/components/UnitIncome/UnitIncome.vue';
import SummaryInfo from '@/components/SummaryInfo/SummaryInfo.vue';
import SummaryTable from '@/components/SummaryTable/SummaryTable.vue';
import TitleSecondary from '@/ui-kit/TitleSecondary/TitleSecondary.vue';
import FilterDate from '@/components/FilterDate/FilterDate.vue';
import InfoChart from '@/components/infoChart/InfoChart.vue';
import StubBlockNoData from '@/components/StubBlockNoData/StubBlockNoData.vue';
import LocationInfo from '@/components/LocationInfo/LocationInfo.vue';
import StubConvertation from '@/components/StubConvertation/StubConvertation.vue';
import StubPromoCode from '@/components/StubPromoCode/StubPromoCode.vue';
import incomeModel from '@/models/income';
import StubPreamble from '@/components/StubPreamble/StubPreamble.vue';
import StubDownloadReport from '@/components/StubDownloadReport/StubDownloadReport.vue';
import StubVideo from '@/components/StubVideo/StubVideo.vue';
import UserManager from '@/components/UserManager/UserManager.vue';
import { LOCATION_INFO_DATA } from '@/constants/dashboard';
import { pastDatesOptions } from '@/helpers/datesAndTime';
import { useRoute } from 'vue-router';
import { useI18n } from 'vue-i18n';
import translates from './translates';

export default {
  name: 'DashboardPage',
  components: {
    TitleMain,
    MainSideMenu,
    DashboardLayout,
    UiTabs,
    PopupDropdown,
    SecondaryButton,
    UserActives,
    UnitIncome,
    InfoChart,
    FilterDate,
    TitleSecondary,
    SummaryInfo,
    SummaryTable,
    MoreLink,
    StubBlockNoData,
    LocationInfo,
    StubConvertation,
    StubPromoCode,
    StubPreamble,
    StubDownloadReport,
    StubVideo,
    UserManager,
  },
  mounted() {
    window.scrollTo(0, 0);
  },
  setup() {
    const store = useStore();
    const { t, mergeLocaleMessage } = useI18n();
    mergeLocaleMessage('en', translates.en);
    mergeLocaleMessage('ru', translates.ru);
    const dashboardState = store.state.dashboard;
    const userState = store.state.user;
    const isInvestor = computed(() => userState.isInvestor);
    const menuItems = computed(() => userState.sideMenuItems);
    const stubDiv = ref(null);
    const { height } = useElementSize(stubDiv);
    const divStyle = ref({});
    const requestStatus = ref(false);
    const locationInfoData = ref(LOCATION_INFO_DATA);
    const locationInfoDataForZaim = ref(Object.values(LOCATION_INFO_DATA));

    const keys = Object.keys(locationInfoDataForZaim.value);

    if (keys.length > 1) {
      const lastKey = keys[keys.length - 1];
      const secondLastKey = keys[keys.length - 2];

      const temp = locationInfoDataForZaim.value[lastKey];
      locationInfoDataForZaim.value[lastKey] = locationInfoDataForZaim.value[secondLastKey];
      locationInfoDataForZaim.value[secondLastKey] = temp;
    }

    const userUnits = computed(() => userState.units);
    const userManagerData = computed(() => userState.managerData);
    const userLocations = computed(() => userState.locations
      .map((location) => ({ ...location, label: location.name, labelEn: location.nameEn })));
    const currentUserLocation = computed(() => userState.currentLocation);

    const popupData = computed(() => ({
      header: t('popupData.roiPerYear.header'),
      text: t('popupData.roiPerYear.text'),
    }));

    const invested = computed(() => dashboardState.invested
      ? `${updateMoneySum(dashboardState.invested)}\u202F₽`
      : '0\u202F₽');
    const totalIncome = computed(() => (dashboardState.totalIncome
      ? `${updateMoneySum(dashboardState.totalIncome)}\u202F₽`
      : '0\u202F₽'));
    const averagePercentForYear = computed(() => (dashboardState.averagePercentForYear
      ? `${dashboardState.averagePercentForYear}\u202F%`
      : '–'));
    const averageMonthlyIncome = computed(() => (dashboardState.averageMonthlyIncome
      ? `${updateMoneySum(dashboardState.averageMonthlyIncome)}\u202F₽`
      : '0\u202F₽'));
    // const incomeAmount = computed(() => (dashboardState.actualDataInSelectedDate
    // && dashboardState.actualDataInSelectedDate
    //   ? `${updateMoneySum(dashboardState.actualDataInSelectedDate.amount)}\u202F₽`
    //   : ''));

    const incomeAmount = computed(() => (dashboardState?.actualDataAll?.[0]?.amount));

    const bookingAmount = computed(() => (dashboardState.actualDataInSelectedDate
      ? dashboardState.actualDataInSelectedDate.bookingAmount
      : ''));
    const incomeAmountPercent = computed(() => (dashboardState.actualDataInSelectedDate
      ? dashboardState.actualDataInSelectedDate.previousMonthIncomePercent
      : null));
    const bookingAmountPercent = computed(() => (dashboardState.actualDataInSelectedDate
      ? dashboardState.actualDataInSelectedDate.previousMonthBookingPercent
      : null));
    const bookingGraphData = computed(() => dashboardState.bookingGraphData);

    const todayDate = new Date();
    const pastDatesOptionsList = ref(pastDatesOptions.months());
    const graphDatesRange = ref([pastDatesOptionsList.value[1].id, todayDate.getTime()]);
    const isLoadingActualData = computed(() => dashboardState.isLoadingActualData);
    const actualDataDates = computed(() => dashboardState.actualDataDates);
    const actualDataSelectedDate = computed(() => dashboardState.actualDataSelectedDate);

    const initDashboardData = async () => {
      await store.dispatch('base/setCurrentPath', useRoute());
      await store.dispatch('user/initUsersLocations');
      await store.dispatch('user/initSideMenuItems');
      if (!userState.profileSettings.name) {
        await store.dispatch('user/getUserProfileSettings');
      }
      store.dispatch('dashboard/getDashboardActualData');
      store.dispatch('dashboard/getDashboardStatistics');
      store.dispatch('dashboard/setBookingGraphDateRange', graphDatesRange.value);
      await store.dispatch('dashboard/getDashboardGraphData');
    };

    const graphDatesChangeHandler = (range) => {
      store.dispatch('dashboard/setBookingGraphDateRange', range);
      store.dispatch('dashboard/getDashboardGraphData');
    };

    const selectActualDataDateHandler = (listItem) => {
      console.log(listItem, 'listItem');
      store.dispatch('dashboard/getDashboardActualDataFilter', { yearMonth: listItem[0].yearMonth });
      store.dispatch('dashboard/setActualDataSelectedDate', listItem[0]);
    };

    const locationChangeHandler = (id) => {
      store.dispatch('user/setCurrentLocation', id);
      initDashboardData();
    };

    const sendConversionRequest = async () => {
      const resp = await incomeModel.sendConversionRequest();
      if (resp.ok) {
        requestStatus.value = true;
      }

      setTimeout(() => {
        requestStatus.value = false;
      }, 5000);
    };

    initDashboardData();

    onMounted(() => {
      divStyle.value = {
        height: `${height.value}px`,
      };
    });

    return {
      userUnits,
      menuItems,
      userLocations,
      currentUserLocation,
      invested,
      totalIncome,
      averagePercentForYear,
      averageMonthlyIncome,
      pastDatesOptionsList,
      graphDatesRange,
      bookingGraphData,
      isLoadingActualData,
      actualDataDates,
      actualDataSelectedDate,
      incomeAmount,
      incomeAmountPercent,
      bookingAmount,
      bookingAmountPercent,
      locationInfoData,
      isInvestor,
      graphDatesChangeHandler,
      selectActualDataDateHandler,
      locationChangeHandler,
      sendConversionRequest,
      requestStatus,
      stubDiv,
      height,
      divStyle,
      userManagerData,
      popupData,
      t,
      locationInfoDataForZaim,
    };
  },
};
</script>

<style lang="scss" scoped>

.dashboard-page {
  &__stub {
    margin-top: 10px;

    &:first-child {
      margin-top: 0;
    }
  }

  &__request {
    &-status {
      font-size: $font-size-medium;
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      background-color: $green100;
      color: $main-black;
      border: 1px solid $grey200;
      margin-bottom: 20px;
      border-radius: 12px;

      &-row {
        display: flex;
        margin-left: 168px;

        h4 {
          font-size: $font-size-title;
        }

        img {
          margin-right: 8px;
        }
      }
    }
  }

  &__title {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__graph {
    margin-top: 60px;
  }

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 0 16px;
  }

  &__about {
    display: flex;
    flex-direction: column;

    .location-info {
      margin-top: 20px;

      &:first-child {
        margin-top: 0;
      }
    }

    &+.dashboard-page__header {
      margin-top: 60px;
    }

    &--not-first {
      margin-top: 10px;
    }
  }

  &__controls {
    display: flex;
    margin-top: 16px;
    padding-bottom: 24px;
    border-bottom: 1px solid $grey200;

    &-item {
      margin-left: 12px;

      &:first-child {
        margin-left: 0;
      }

      .secondary-button {
        text-transform: capitalize;
      }
      .popup-dropdown__button {
        text-transform: capitalize;
      }
    }
  }

  &__table {
    margin-top: 16px;
  }

  &__actual-data {
    margin-top: 60px;
  }
}

@media screen and (max-width: $tablet-breakpoint) {
  .dashboard-page {
    &__request-status {
      margin-top: 35px;
    }

    &__summary-table {
      .summary-table {
        &__row {
          width: 100%;
          flex-direction: row;
          flex-wrap: wrap;
        }

        &__block {
          max-width: 50%;
          min-width: 50%;

          &:nth-child(3) {
            border-top: 1px solid $grey200;
          }

          &:nth-child(even) {
            border-left: 1px solid $grey200;
            padding-left: 12px;
          }
        }
      }
    }

    &__actual-data {
      .summary-table {
        &__row {
          width: 100%;
          border-top: none;
          flex-direction: row;
        }

        &__block {
          flex: 1;

          &:first-child {
            border-top: 1px solid $grey200;
            padding-right: 12px;
          }

          &:last-child {
            padding-left: 12px;
            border-left: 1px solid $grey200;
          }
        }
      }
    }
  }
}

@media screen and (max-width: $mobile-breakpoint) {
  .dashboard-page {
    &__request-status {
      margin-top: 0;
      width: calc(100% + 40px);
      margin-left: -20px;

      &-row {
        display: flex;
        margin-left: 32px;

        h4 {
          font-size: $font-size-medium;
        }

        img {
          margin-right: 8px;
        }
      }
    }

    &__about {
      width: calc(100% + 48px);
      margin: 20px 0 0 -24px;
    }

    &__title {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      gap: 12px 0;
    }

    &__summary-table {
      .summary-table {
        &__row {
          flex-direction: column;
        }

        &__block {
          min-width: 100%;
          max-width: 100%;

          &:nth-child(3) {
            border-top: 1px solid $grey200;
          }

          &:nth-child(even) {
            border-left: none;
            padding-left: 0;
          }
        }
      }
    }

    &__actual-data {
      .summary-table {
        &__row {
          flex-direction: column;
        }

        &__block {
          &:first-child {
            padding-right: 0;
          }

          &:last-child {
            padding-left: 0;
            border-left: none;
          }
        }
      }
    }
  }
}
</style>
