const messages = {
  en: {
    investor: 'investor',
    settings: 'Account settings',
    actives: 'My assets',
    documents: 'My documents',
    exit: 'Log out',
    news: 'News',
    faq: 'FAQ',
    promo: 'Marketplace',
    unit: 'unit | units | units',
    logIn: 'Log in',
    menuMain: 'Summary',
    menuIncome: 'Your income',
    menuTimeline: 'Project timeline',
    menuReports: 'Project reports',
    menuUnits: 'Room inventory',
    menuBooking: 'Reservations',
    menuIncomeOutcome: 'Income and expenses',
    menuPeriphery: 'Outbuildings',
    menuControls: 'Quality control',
    menuCameras: 'Cameras',
    menuChecks: 'Checks',
    menuInventory: 'Inventory',
    menuTooltipHeaderUnavailable: 'Section unavailable',
    menuTooltipTextUnavailable: 'You don\'t have any {msg}, so this section is not available to you.',
    weekAgo: 'A week ago',
    twoWeekAgo: 'Two week ago',
    monthAgo: 'A month ago',
    twoMonthAgo: 'Two months ago',
    inMonth: 'In a month',
    inTwoMonths: 'In two months',
    source: 'Source',
    today: 'Today',
    cancel: 'Cancel',
    send: 'Send',
    selectedDate: 'Selected date',
    change: 'Change',
    password: 'Password',
    changePassword: 'Change password',
    save: 'Save',
    notSpecified: 'Not specified',
    needSign: 'There\'s a document that needs to be signed',
    bookingSources: {
      reception: 'Reception',
      call: 'Phone call',
      sales: 'From the sales channel',
      web: 'Hotel\'s website',
      fax: 'Fax',
      email: 'Email',
      vk: 'VK',
      mobile: 'Mobile website',
      fb: 'Facebook',
      ta: 'TripAdvisor',
      extranet: 'Booking from an extranet',
      ya: 'Yandex',
      mobileExtranet: 'Mobile extranet',
    },
    lenderMenuTooltipHeaderUnavailabe: 'This section is only for users with an investment agreement',
    lenderMenuTooltipTextUnavailable: 'Contact sales to learn more.',
    requestForDocument: 'Request for document',
    requestForDocumentText: 'Fill out the application for receiving the document. '
      + 'We will process it and add the document to this page. If we have any questions '
      + 'while processing the request, we will contact you.',
    selectDocument: 'Select document',
    commentDocument: 'Leave a comment if needed',
    request: 'Request',
    sellAsset: 'Sell an asset',
    soldAsset: 'Done! Asset is sold',
    ok: 'Ok',
    removeFromAsset: 'Remove from sale',
    areYouSure: 'Are you sure?',
    confirm: 'Confirm',
    done: 'Done!',
    leaveRequest: 'Leave a request',
    sendLeaveReqMessage: 'We will forward your request to the seller and the '
      + 'inwis sales department, they will contact you to discuss the details '
      + 'of the transaction.',
    applicationHasSend: 'Your application has been sent.',
    doneRequestSend: 'Done! Your request has been sent',
    weWillUpload: 'We will upload the document to this page or contact you to clarify details using the contacts provided in your profile.',
    yourAd: 'Your ad will no longer be on',
    offerPage: 'offer pages.',
    offerPageDelete: 'proposal page.',
    wantToList: 'If you wish to list the unit for sale again, you can do so on this page.',
    theAdIsLocated: 'The ad is located on',
    wantToCancle: 'If you want to cancel it, you can do so on this page.',
    profitability: 'Current profitability',
    removedFromSale: 'Your unit has been successfully removed from sale.',
    specifyCost: 'Specify the cost of the unit and we will create an ad for',
    emailText: 'When one of the investors is interested in your message,'
      + ' we will send a notification to the email specified in your account.',
    listedForSale: 'Listed for sale',
    price: 'Cost in ₽',
    promoThisPageYouCan: 'On this page you can see the lots of inwis investors'
      + ' and leave requests for the offers you like.',
    annual: 'Annual',
    average: 'average',
    profit: 'profitability',
    fromSigning: 'from signing the contract to receiving the first income',
    month: 'month',
    guaranteed: 'guaranteed',
    minimumIncome: 'minimum income',
    annualAverage: 'annual - average yield',
    annualGaranted: 'annual - guaranteed minimum income',
    signingContract: 'from signing the contract to receiving the first income',
    lots: 'Lots',
    viewUnit: 'View units',
    greatOffer: 'Great offer',
    stage: 'Stage',
    priceLot: 'Price',
    myLot: 'My lot',
    investorRole: 'Investor',
    authModalTitle: 'To continue, please enter the six-digit code we sent you to ',
    authModalSubTitle: 'If your number has changed, please request a contact change on your account settings page.',
    dontReceavedCode: 'Didn\'t receive the code? ',
    code: 'Code',
    authentication: 'Authentication',
    resendCode: 'Resend Code',
    resendCodeAvailable: 'Resend Code',
    resendAvailableIn: 'Resend available in',
    secondsRemaining: 'seconds remaining',
    wentWrong: 'Something is wrong',
    codeSuccess: 'Done! Now we will request not only the password, but also the code from the phone when entering the personal account.',
    authenticationOn: 'Authentication enabled',
    authenticationOff: 'Authentication is disabled',
    authenticationOffSubText: 'Done! Now we won\'t ask for a code from your phone when you log into your personal account.',
    authenticationSubTitle: 'We will send codes to confirm actions in your personal account',
    enterCodeFromSms: 'Enter the code from the SMS',
    didntReceivedCode: 'Didn\'t receive the code?',
    sendAgain: 'Send again',
    showIncome: 'Shown is income after withholding personal income tax',
    inProgress: 'In progress',
    awaitingCommencement: 'Awaiting commencement of work',
    noReportsYet: 'No reports yet',
    whenTheyAppear: 'When they appear, you will see them on this page.',
    summeryData: 'Summary data for the complex',
    todayWorkload: 'Today\'s workload',
    numbersOccupied: 'Numbers occupied',
    guestsToday: 'Guests today',
    weLeft: 'We left',
    roomCondition: 'Room condition',
    roomReservations: 'Room reservations in the chessboard are displayed per day, i.e. one block is one day.',
    onThisPage: 'On this page you can track the service and technical condition checks that we regularly conduct on site.',
    dateLastCheck: 'Date of last check',
    averageResult: 'Average result',
    inspections: 'Inspections carried out',
    resultInspections: 'Results of inspections',
    percentagesShow: 'Percentages show the success of the check. 100% - no violations were found during the check.',
    allCheck: 'All checks',
    typeCheck: 'Type of check',
    result: 'Result',
    violations: 'Violations',
    readMore: 'Read more',
    serviceControl: 'Service control',
    otherChecks: 'Other checks',
    materialBase: 'Material base and operation',
    technicalCondition: 'Technical condition of the object',
    checkQuality: 'Checking the quality of service',
    upcoming: 'Upcoming',
    past: 'Past',
    noChecksYet: 'No checks yet',
    complexDidntHave: 'The complex does not have any inspections on these dates yet.'
      + 'As soon as they appear, you will see them on this page.',
    schemaOfInteraction: 'Scheme of interaction of legal entities of the dewis group of companies',
    legalEntities: 'Legal entities of the group of companies',
    areasOfResponsibility: 'Areas of responsibility of each legal entity',
    productionCycle: 'Production cycle of hotel complexes VIEW GA',
    openDiagram: 'Open diagram',
    interimFinancial: 'Interim financial report on the work of the dewis group of companies',
    viewReport: 'View report',
    polinaPrikhodko: 'Polina Prikhodko, co-founder of the INWIS project, on the legal entities of the'
      + ' dewis group of companies',
    theVideoProvided: 'The video provided detailed information about the areas of '
      + 'responsibility and financial indicators of each company.',
    inwisFounder: 'INWIS co-founder Polina Prikhodko on her personal account',
    detailedManual: 'A detailed manual for the lender about the structure and functions of the personal account.',
    yourManager: 'Your manager',
    lender: 'Lender',

  },
  ru: {
    investor: 'инвестор',
    settings: 'Настройки аккаунта',
    actives: 'Мои активы',
    documents: 'Мои документы',
    send: 'Отправить',
    exit: 'Выйти',
    news: 'Новости',
    faq: 'FAQ',
    unit: 'юнит | юниты | юнитов',
    logIn: 'Вход',
    menuMain: 'Главная',
    menuIncome: 'Ваши доходы',
    menuTimeline: 'Таймлайн проекта',
    menuReports: 'Отчеты проекта',
    menuUnits: 'Номерной фонд',
    menuBooking: 'Бронирования',
    menuIncomeOutcome: 'Доходы и расходы',
    menuPeriphery: 'Периферия',
    menuControls: 'Контроль качества',
    menuCameras: 'Камеры',
    menuChecks: 'Проверки',
    menuInventory: 'Инвентаризации',
    menuTooltipHeaderUnavailable: 'Раздел недоступен',
    menuTooltipTextUnavailable: 'У вас нет {msg}, поэтому этот раздел вам недоступен.',
    weekAgo: 'Неделя назад',
    promo: 'Лоты от инвесторов',
    twoWeekAgo: 'Две недели назад',
    monthAgo: 'Месяц назад',
    twoMonthAgo: 'Два месяца назад',
    inMonth: 'Через месяц',
    inTwoMonths: 'Через два месяца',
    source: 'Источник',
    today: 'Сегодня',
    selectedDate: 'Выбранное число | Выбранная дата',
    change: 'Изменить',
    changePassword: 'Изменить пароль',
    password: 'Пароль',
    cancel: 'Отменить',
    save: 'Сохранить',
    notSpecified: 'Не указано',
    needSign: 'Есть документ, который надо подписать',
    bookingSources: {
      reception: 'От стойки',
      call: 'По звонку',
      sales: 'Из канала продаж',
      web: 'Сайт гостиницы',
      fax: 'Факс',
      email: 'Email',
      vk: 'ВКонтакте',
      mobile: 'Мобильная версия сайта',
      fb: 'Facebook',
      ta: 'TripAdvisor',
      extranet: 'Бронирование из экстранета',
      ya: 'Yandex',
      mobileExtranet: 'Мобильный экстранет',
    },
    lenderMenuTooltipHeaderUnavailabe: 'Раздел только для пользователей с инвестиционным договором',
    lenderMenuTooltipTextUnavailable: 'Свяжитесь с отделом продаж, чтобы узнать больше.',
    requestForDocument: 'Запрос документа',
    requestForDocumentText: 'Заполните заявку на получение документа.'
      + ' Мы обработаем ее и добавим документ на эту страницу. '
      + 'Если в процессе обработки запроса у нас появятся вопросы —'
      + ' мы свяжемся с вами.',
    selectDocument: 'Выберите документ',
    commentDocument: 'Напишите комментарий, если нужно',
    request: 'Запросить',
    sellAsset: 'Продать актив',
    soldAsset: 'Готово! Лот выставлен',
    ok: 'Хорошо',
    removeFromAsset: 'Снять с продажи',
    areYouSure: 'Вы уверены?',
    confirm: 'Подтвердить',
    done: 'Готово!',
    leaveRequest: 'Оставить заявку',
    sendLeaveReqMessage: 'Мы отправим вашу заявку продавцу и отделу продаж inwis, они свяжутся с вами, чтобы обсудить детали сделки.',
    applicationHasSend: 'Ваша заявка отправлена.',
    doneRequestSend: 'Готово! Ваш запрос отправлен',
    weWillUpload: 'Мы&nbsp;загрузим документ на&nbsp;эту страницу или свяжемся с&nbsp;вами для уточнения деталей по&nbsp;контактам, указанным в&nbsp;профиле.',
    yourAd: 'Вашего объявления больше не будет на',
    offerPage: 'страницы предложений.',
    offerPageDelete: 'странице предложений.',
    wantToList: 'Если вы захотите снова выставить юнит на продажу, это можно сделать на этой странице.',
    theAdIsLocated: 'Объявление находится на&nbsp;',
    wantToCancle: ' Если вы&nbsp;захотите его отменить, это можно сделать на&nbsp;этой странице.',
    profitability: 'Текущая доходность',
    specifyCost: 'Укажите стоимость юнита, и&nbsp;мы&nbsp;сформируем объявление для ',
    emailText: ' Когда кто-то из&nbsp;инвесторов заинтересуется вашим'
      + ' сообщением, мы&nbsp;отправим оповещение на&nbsp;почту, указанную у&nbsp;вас в&nbsp;аккаунте.',
    removedFromSale: 'Ваш юнит успешно снят с продажи.',
    listedForSale: 'Выставлено на продажу',
    price: 'Стоимость в ₽',
    promoThisPageYouCan: 'На этой странице вы можете увидеть лоты инвесторов inwis'
      + '  и оставить заявки на понравившиеся вам предложения.',
    annual: 'годовых',
    average: 'средняя',
    profit: 'доходность',
    fromSigning: 'от подписания договора до получения первого дохода',
    month: 'мес',
    guaranteed: 'гарантированный',
    minimumIncome: 'минимальный доход',
    annualAverage: 'годовых — средняя доходность',
    signingContract: 'от подписания договора до получения первого дохода',
    annualGaranted: 'годовых — гарантированный минимальный доход',
    lots: 'Лоты',
    viewUnit: 'Посмотреть юниты',
    greatOffer: 'Выгодное предложение',
    stage: 'Стадия',
    priceLot: 'Стоимость',
    myLot: 'Мой лот',
    investorRole: 'Инвестор',
    authModalTitle: 'Чтобы продолжить, пожалуйста, введите <br/> шестизначный код, который мы отправили вам на телефон ',
    authModalSubTitle: 'Если у вас изменился номер, запросите смену <br/> контактов на странице настроек аккаунта.',
    dontReceavedCode: 'Не получили код? ',
    resendCode: 'Отправить повторно',
    code: 'Код',
    authentication: 'Аутентификация',
    resendCodeAvailable: 'Отправить код',
    resendAvailableIn: 'Отправка доступна через',
    secondsRemaining: 'секунд осталось',
    wentWrong: 'Что-то не так',
    authenticationOn: 'Аутентификация включена',
    authenticationOff: 'Аутентификация выключена',
    authenticationOffSubText: 'Готово! Теперь мы не будем запрашивать код <br/> с телефона при входе в личный кабинет.',
    codeSuccess: 'Готово! Теперь мы будем запрашивать не только <br/> пароль, но и код с телефона при входе в личный <br/> кабинет.',
    authenticationSubTitle: 'Будем присылать коды для подтверждения действий в личном кабинете',
    enterCodeFromSms: 'Введите код из смс',
    didntReceivedCode: 'Не пришел код?',
    sendAgain: 'Отправить еще раз',
    showIncome: 'Показан доход после удержания НДФЛ',
    inProgress: 'В процессе',
    awaitingCommencement: 'Ожидает начала работ',
    noReportsYet: 'Пока нет отчетов',
    whenTheyAppear: 'Когда они появятся, вы увидите их на этой странице.',
    summeryData: 'Cводные данные по комплексу',
    todayWorkload: 'Загруженность на сегодня',
    numbersOccupied: 'Занято номеров',
    guestsToday: 'Гости сегодня',
    weLeft: 'Выехали',
    roomCondition: 'Состояние номеров',
    roomReservations: 'Бронирования номеров в шахматке отображаются посуточно, то есть один блок — это один день.',
    onThisPage: 'На этой странице вы можете отслеживать проверки сервиса и технического состояния, '
      + 'которые мы регулярно проводим на локации.',
    dateLastCheck: 'Дата последней проверки',
    averageResult: 'Средний результат',
    inspections: 'Проведено проверок',
    resultInspections: 'Результаты проверок',
    percentagesShow: 'Проценты показывают успешность проверки.'
      + '100% —  во время проверки не обнаружено нарушений.',
    allCheck: 'Все проверки',
    typeCheck: 'Тип проверки',
    result: 'Результат',
    violations: 'Нарушений',
    readMore: 'Подробнее',
    serviceControl: 'Контроль сервиса',
    otherChecks: 'Другие проверки',
    materialBase: 'Материальная база и эксплуатация',
    technicalCondition: 'Техническое состояние объекта',
    checkQuality: 'Проверка качества обслуживания',
    upcoming: 'Предстоящие',
    past: 'Прошедшие',
    noChecksYet: 'Пока нет проверок',
    complexDidntHave: 'У комплекса пока нет проверок в эти даты. '
      + 'Как только они появятся, вы увидите их на этой странице.',
    schemaOfInteraction: 'Схема взаимодействия юридических лиц группы компаний dewis',
    legalEntities: 'Юридические лица группы компаний',
    areasOfResponsibility: 'Зоны ответственности каждого юридического лица',
    productionCycle: 'Производственный цикл гостиничных комплексов VIEW GA',
    openDiagram: 'Открыть схему',
    interimFinancial: 'Промежуточный финансовый отчет о работе группы компаний dewis',
    viewReport: 'Посмотреть отчет',
    polinaPrikhodko: 'Полина Приходько, сооснователь проекта INWIS, о юридических лицах группы компаний dewis',
    theVideoProvided: 'В видео подробно рассказали о зонах ответственности и финансовых показателях каждой из компаний.',
    inwisFounder: 'Сооснователь INWIS Полина Приходько o личном кабинете',
    detailedManual: 'Подробный мануал для заимодателя об устройстве и функциях личного кабинета.',
    yourManager: 'Ваш менеджер',
    lender: 'Займы',

  },
};

export default {
  legacy: false,
  globalInjection: true,
  fallbackLocale: 'en',
  messages,
};
